/**
 * Hero debugging tool
 * Styles are in _hero_helper.scss
 *
 * Usage:
 * append url with ?herohelp=true
 */

(function($){

  Drupal.behaviors.heroHelper = {
    attach: function(context) {

      function getQueryString() {
        var vars = [], hash;
        var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
        for(var i = 0; i < hashes.length; i++) {
          hash = hashes[i].split('=');
          vars.push(hash[0]);
          vars[hash[0]] = hash[1];
        }
        return vars;
      }

      // load the debug layer
      // we use once() to prevent ajax from re-triggering this event
      var debughelper = getQueryString()['herohelp'];
      var $heroLarge = $('.content-block-large', context);

      // if the helper is on, prepend each hero type with the appropriate debug helper
      if (debughelper === 'true') {

        // large hero aka content block
        if ($heroLarge.length) {
          $heroLarge.once('herohelper', function () {
            $(this).addClass('hero-debug').prepend('<div class="hero-helper hero-helper--large"></div>');
          });
        }

      }

    }
  };

})(jQuery);
